.star-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filled-star {
    font-size: 15px;
    font-weight: bold;
    margin-right: 4px;
    color: gold;
}

.hollow-star {
    font-size: 15px;
    font-weight: bold;
    margin-right: 4px;
    color: lightgray;
}

.star-rating__text {
    font-weight: 400;
    font-size: 14px;
    margin-left: 6px;
}