.shimmer-loader {
    /* background-color: red; */
    background: rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.037) 100%);
    background-repeat: no-repeat;
    flex-grow: 1;
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.border-radius-4 {
    border-radius: 4px;
}

.border-radius-8 {
    border-radius: 8px;
}

.border-radius-12 {
    border-radius: 12px;
}

.view-detail-button {
    width: 180px;
    height: 54px;
}

.game-icon {
    width: 48px;
    height: 48px;
}

.game-title {
    width: 146px;
    height: 24px;
}

.heading {
    flex-grow: 0;
    width: 200px;
    height: 28px;
}

.token {
    width: 24px;
    height: 24px;
    border-radius: 12px;
}

.game-tile {
    width: 250px;
    height: 146px;
}

/* Landing Page */

.landing-shimmer-loader__wrapper {
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding-left: 48px;
    padding-right: 48px;
}

.landing-shimmer-loader__featured {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.landing-shimmer-loader__banner {
    flex-grow: 1;
    position: relative;
}

.landing-shimmer-loader__banner__button {
    bottom: 24px;
    right: 24px;
    position: absolute;
    display: flex;
    background: rgba(255, 255, 255, 0.2);
}

.landing-shimmer-loader__game-tile-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.landing-shimmer-loader__game-tile {
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}


.landing-shimmer-loader__list-section {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.landing-shimmer-loader__game-list-grid {
    display: flex;
    gap: 20px;
}

/* Game Detail Page */

.detail-page-shimmer {
    display: flex;
    flex-direction: column;
    padding-left: 48px;
    padding-right: 48px;
    gap: 40px;
}

.detail-page-shimmer__game-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-side {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.right-side {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 160px;
}

.game-logo {
    width: 150px;
    height: 100px;
    margin-top: 100px;
}

.game-info {
    width: 300px;
    height: 50px;
}

.game-description {
    width: 400px;
    height: 64px;
}

.monetary-stats {
    width: 350px;
    height: 90px;
}

.play-now {
    width: 350px;
    height: 54px;
}

/* Genre Detail Page */

.genre-shimmer-loader__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 48px;
    padding-right: 48px;
}

.genre-shimmer-loader__toolbar {
    display: flex;
    justify-content: space-between;
}

.genre-gamelist-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}