.category-details {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    padding-right: 48px;
}

.category-details-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.category-details-toolbar__leading {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.category-details-toolbar__title {
    display: flex;
    align-items: center;
}

.category-details-toolbar__ending {
    display: flex;
    align-items: center;
}

.category-details-game-grid {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    padding-left: 48px;

}