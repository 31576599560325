.popup-overlay {
  margin: auto;
  position: fixed;
  top: 50px;
  left: 50px;
  bottom: 50px;
  right: 50px;
  background: transparent;
  height: 100vh;
  min-width: 700px;
  max-width: 54.6vw;
  height: 40rem;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;

  .popup {
    position: relative;
    border-radius: 12px;
    background: linear-gradient(141.64deg, #224468 22.15%, #A94B6A 98.27%);
    padding: 2rem;
    max-height: 80vh;
    align-self: center;

    .close-icon {
      position: absolute;
      right: 2rem;
      top: 2rem;
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }

    .content {
      margin-top: 0.5rem;
    }
  }
}