.multiple-token-tag-pill {
    font-size: 10px;
    font-weight: 400;
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
}

.multiple-token-tag-pill__pill {
    display: flex;
    flex-direction: row;
    gap: 4px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(6px);
    align-items: center;
    justify-content: center;
    padding: 4px 4px 1px 4px;
    min-width: 60px;
}