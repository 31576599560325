.user-reviews {
	margin-top: 40px;
	padding-left: 48px;
	padding-right: 48px;
}

.user-reviews__title {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 20px;
}

.user-reviews__list {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.user-reviews__single-review {
	flex-direction: column;
	display: flex;
	gap: 12px;
}

.user-reviews__single-review__info {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.user-reviews__single-review__username {
	font-weight: 400;
	font-size: 14px;
}

.user-reviews__single-review__date {
	font-weight: 400;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.6);
}

.user-reviews__single-review__details {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.user-reviews__single-review__heading {
	font-weight: 500;
	font-size: 14px;
}

.user-reviews__single-review__description {
	font-weight: 400;
	font-size: 14px;
}