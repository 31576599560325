.game-media__title {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 20px;
}

.game-media__image-list {
	display: flex;
	gap: 24px;
	overflow-y: hidden;
}

.game-media__image {
	border-radius: 8px;
	width: 320px;
	height: 180px;
}
