.something-went-wrong {
    display: flex;
    flex-grow: 1;
    padding: 48px;
    justify-items: center;
    align-items: center;
    flex-direction: column;
}

.something-went-wrong__error-board {
    background-color: rgba(235, 85, 62, 1);
    width: 105px;
    height: 105px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 36px;
}

.something-went-wrong__error-sign {
    font-size: 50px;
    position: absolute;
    top: 25%;
    right: 46%;
}

.something-went-wrong__title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
}

.something-went-wrong__try-again {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
}

.something-went-wrong__refresh-button {
    border-radius: 8px;
    background-color: rgba(26, 159, 255, 1);
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}

.something-went-wrong__refresh-button:hover {
    background-color: rgb(54, 169, 251);
}

.button-title {
    font-weight: 500;
    font-size: 20px;
}