.circular-token-image {
	border-radius: 50%;
	min-width: 24px;
	max-width: 24px;
	min-height: 24px;
	max-height: 24px;
	filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.35));
	background: rgb(0 0 0 / 60%);
	padding: 2px;
	box-sizing: border-box;
}
