.description-widget {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: min-content;
    min-width: 0;

    img {
        height: 40px;
        width: 40px;
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-right: 8px;
        gap: 4px;
        overflow: hidden;

        .title {
            color: white;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            white-space: nowrap;
            display: flex;
            width: min-content;
            position: relative;
            overflow: hidden;
        }

        .sub-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #B6B8CD;
            order: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }
    }
}