.top-nfts {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 52px;
}

.top-nfts__title {
    font-weight: 500;
    font-size: 20px;
}

.top-nfts__nft-list {
    display: flex;
    flex-direction: row;
    gap: 24px;
    overflow-y: hidden;
}

.top-nfts__tile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 160px;
}

.top-nfts__image-container {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.top-nfts__image-wrapper {
    padding: 12px;
}

.top-nfts__image {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: block;
}

.top-nfts__info {
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.top-nfts__info__title {
    font-weight: 400;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.top-nfts__info__amount {
    font-weight: 400;
    font-size: 14px;
}

.top-nfts__image-container__view-details-button {
    position: absolute;
    bottom: -30px;
    height: 30px;
    width: 160px;
}

/* .top-nfts__tile:hover .top-nfts__image-container__view-details-button {
    animation: move-up 250ms forwards;
}

.top-nfts__tile:hover .top-nfts__image-wrapper {
    animation: scale-up 250ms forwards;
} */

/* @keyframes scale-up {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1);
    }
}

@keyframes move-up {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-30px);
    }
} */