.scroll-buttons {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.scroll-button-backward {
    transform: rotate(180deg);
    width: 28px;
    height: 28px;
}

.scroll-button-froward {
    width: 28px;
    height: 28px;
}

.scroll-btn {
    width: 28px;
    height: 28px;
    cursor: pointer;
}