.tag-pill-token {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.tag-pill-token-type {
    font-size: 10px;
    font-weight: 400;
}

.tag-pill {
    display: flex;
    height: 24px;
    gap: 4px;
    align-items: center;
    padding: 4px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.3);
}

.tag-pill__title {
    font-weight: 500;
    font-size: 14px;
    margin-right: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
    max-width: 100px;
    overflow: hidden;
}