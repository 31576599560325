.getting-started-with-web3 {
    background: linear-gradient(135.29deg, #224468 32.9%, #A94B6A 91.58%);
    border-radius: 12px;
    padding: 1px;

    .web3-card-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px 100px 0px 20px;
        gap: 6px;
        height: 90px;
        background: #1B1E38;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        position: relative;
        isolation: isolate;
        overflow: hidden;

        .web3-card-overlay {
            position: absolute;
            width: 100px;
            height: 100px;
            right: 0px;
            bottom: 0px;
            background: url('../../../assets/ic_fighting_game.png');
            background-position: 6px 10px;
            background-repeat: no-repeat;
            filter: drop-shadow(0px 4.8px 19.2px #AA391F);
        }

        .web3-card-header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 203px;
            height: 24px;

            .web3-card-icon {
                width: 24px;
                height: 24px;
            }

            .web3-card-title {
                height: 22px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                display: flex;
                align-items: center;
                color: #FFFFFF;               
            }
        }

        .web3-card-summary {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #B6B8CD;
            flex: none;
            z-index: 2;
        }
    }

}