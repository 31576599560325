.featured {
	display: flex;
	flex-direction: row;
	gap: 8px;
	padding-left: 48px;
	padding-right: 48px;
}

.featured-icon {
	margin-left: 24px;
	margin-top: 24px;
	width: 36px;
	height: 50px;
}

.featured__focussed-image {
	position: relative;
	flex-grow: 1;
	border-radius: 8px;
	background-position: center;
	background-size: cover;
	min-height: 344px;
	min-width: 525px;
	z-index: 3;
}

.featured__view-details-button {
	color: white;
	width: 180px;
	min-width: 180px;
	height: 54px;
	bottom: 0px;
	right: 0px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	font-weight: 500;
	background: #1a9fff;
	font-size: 20px;
	cursor: pointer;
	z-index: 4;
	padding: 0px 8px;
}

.featured__view-details-button:hover {
	background: #38acff;
}

.btn--disabled {
	cursor: default;
}

.featured__carousel {
	display: flex;
	gap: 8px;
	flex-direction: column;
	justify-content: center;
}

.featured__carousel__tile {
	border: 2px solid transparent;
	border-radius: 12px;
	width: 240px;
	height: 80px;
	position: relative;
	overflow: hidden;
	background-color: rgba(24, 28, 45, 0.7);
	display: flex;
	padding: 16px;
	box-sizing: border-box;
	gap: 8px;
	cursor: pointer;
	align-items: center;
}

.featured__carousel__tile--highlighted {
	border-color: #ffd500;
}

.bg-anim {
	display: none;
}

.featured__carousel__tile--highlighted .bg-anim {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #ffd500;
	top: 0px;
	left: 0px;
	border-radius: 8px;
	animation: fill-bg 5s linear forwards;
	z-index: -1;
}

@keyframes fill-bg {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translateX(0%);
	}
}

.featured__carousel__tile__img {
	height: 48px;
	width: 48px;
	border-radius: 6px;
}

.featured__carousel__tile__details {
	display: flex;
	flex-direction: column;
	font-style: normal;
	line-height: 140%;
}

.featured__carousel__tile__details__name {
	font-weight: 700;
	font-size: 18px;
}

.featured__carousel__tile__details__studio {
	font-weight: 400;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.7);
}

.view-details-cta {
	position: absolute;
	padding-left: 24px;
	padding-right: 24px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	bottom: 24px;
    width: -webkit-fill-available;
}

.first-web3-game {
	padding-bottom: 4px;
	margin-right: 8px;
	display: flex;
	overflow: hidden;
	min-width: 0;
}

.featured-section-overlay {
	position: absolute;
	width: 100%;
	height: 78px;
	left: 0;
	bottom: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
	border-radius: 0 0 8px 8px;
}