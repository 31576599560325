.view-details-button {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(71, 159, 248);
	height: 100%;
	width: 100%;
	cursor: pointer;
	overflow: hidden;
	font-weight: 500;
}

.button--not-clickable {
	cursor: default;
}

.view-details-button:hover {
	background: #38acff;
}