.section {
	margin-top: 40px;
}

.section-no-right-padding {
	margin-right: 0px;
}

.section__top {
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
	margin-inline: 48px;
}

.section__top__view-all-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	background: #1a9fff;
	height: 36px;
	width: 84px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	cursor: pointer;
}

.btn--disabled {
	cursor: default;
}

.section__top__view-all-btn:hover {
	background: #38acff;
}

.section__top__title {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
}

.section__game-list {
	display: flex;
	gap: 24px;
	overflow-y: hidden;
	padding-inline: 48px;
}