.genre {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    width: 160px;
    height: 52px;
    min-width: 120px;
    color: white;
    font-style: normal;
    cursor: pointer;
}