.getting-started__title {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 20px;
}

.getting-started__steps {
	display: flex;
	justify-content: space-between;
	overflow-y: hidden;
	align-items: center;
	padding-inline: 48px;
}

.getting-started__steps__delimiter {
	width: 6px;
	height: 12px;
	margin-left: 16px;
	margin-right: 16px;
}

.getting-started__single-step {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	padding: 24px;
	align-self: stretch;
	width: 30%;
}

.getting-started__single-step__image {
	width: 128px;
	height: 128px;
}

.getting-started__single-step__info {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
}

.getting-started__single-step__number {
	font-weight: 400;
	font-size: 14px;
}

.getting-started__single-step__title {
	font-weight: 500;
	font-size: 20px;
	text-align: center;
}
