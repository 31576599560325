.back-button {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	text-decoration: none;
	color: white;
	cursor: pointer;
	margin-left: 48px;
}

.back-button__ic {
	width: 14px;
	height: 14px;
	border-radius: 50%;
	border: 2px solid rgb(249, 212, 72);
	padding: 4px;
}

.back-button__text {
	font-size: 18px;
	font-weight: 400;
}