@import './components/game_tile/game_tile.css';
@import './components/list_section/list_section.css';
@import './components/circular_token_image/circular_token_image.css';
@import './components/view_details_button/view_details_button.css';
@import './components/featured_section/featured_section.css';
@import './components/detail_page_header/detail_page_header.css';
@import './components/star_rating/star_rating.css';
@import './components/back_button/back_button.css';
@import './components/single_token_tag_pill/single_token_tag_pill.css';
@import './components/game_media/game_media.css';
@import './components/top_nfts/top_nfts.css';
@import './components/getting_started/getting_started.css';
@import './components/user_reviews/user_reviews.css';
@import './components/genre/genre.css';
@import './components/scroll_buttons/scroll_button.css';
@import './components/multiple_token_tag_pill/multiple_token_tag_pill.css';
@import './components/category_detail_page/category_detail_page.css';
@import './components/read_more/read_more.css';
@import './components/shimmer_effect/shimmer_effect.css';
@import './components/something_went_wrong/something_went_wrong.css';

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
	font-family: 'Rubik', sans-serif;
	margin: 0;
}

::-webkit-scrollbar {
	width: 4px;
	height: 0px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 4px;
}

a:link {
	text-decoration: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.flex {
	display: flex;
	justify-content: space-between;
}

.space-between {
	justify-content: space-between;
}

.overlay {
	height: -webkit-fill-available;
	position: fixed;
	z-index: 2;
	width: 100%;
	background:linear-gradient(114.89deg,rgb(31, 35, 51,0.9) 43%,rgb(31, 35, 51,0.9));
}

#listing-page,
#detail-page,
.category-details {
	padding-top: 48px;
	padding-bottom: 48px;
}

.cursor-pointer{
	cursor: pointer;
}

.invisible{
	opacity: 0;
	cursor: default;
}