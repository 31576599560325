.animate {
    position: relative;
    animation: leftright 3s infinite alternate ease-in-out;
}

@keyframes leftright {

    0%,
    20% {
        transform: translateX(0%);
        left: 0%;
    }

    80%,
    100% {
        transform: translateX(calc(-100% + 130px));
        left: 0%;
    }
}