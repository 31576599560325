.ripples {
    height: 80px;
    position: absolute;
    top:12px;
    width: 80px;
    
    > div {
      animation: growAndFade 1s infinite ease-out;
      background-color: #ffd500;
      border-radius: 50%;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
    }
    .ripple1 {
      animation-delay: 1s;    
    }
  }
  
  @keyframes growAndFade {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }