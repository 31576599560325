.read-more-popup {
    .header {
        display: flex;
        img {
            height: 72px;
            width: 72px;
            border-radius: 12px;
        }
        .game-props{
            padding-left: 24px;
            margin: auto 0;
            .game-name{
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
            }
            .game-tags{
                display: flex;
                flex-wrap: wrap;
                .tag{
                    margin-right: 12px;
                    margin-top: 12px;
                    background: rgba(0, 0, 0, 0.3);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    backdrop-filter: blur(6px);
                    border-radius: 20px;
                    padding: 4px 8px;
                    color: white;
                    font-size: 10px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }
    }
    .description{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        padding-top: 12px;
    }
}