.game-tile {
	min-width: 250px;
	max-width: 250px;
	height: 146px;
	border-radius: 8px;
	position: relative;
	background-size: cover;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: center;
}

.game-tile--clickable {
	cursor: pointer;
}

.game-tile__logo {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	background-size: 100px;
	background-repeat: no-repeat;
	background-position: center bottom 30px;
	z-index: 1;
}

.game_tile__backdrop {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	background: linear-gradient(180deg,
			rgba(0, 0, 0, 0) 36.3%,
			rgba(0, 0, 0, 0.7) 100%);
}

.game-tile__overlay__view-detail-button {
	position: absolute;
	bottom: -40px;
	height: 40px;
	width: 100%;
}

.game-tile:hover .game-tile__overlay__view-detail-button,
.game-tile:hover .game-tile__logo {
	animation: move-up 250ms forwards;
}

@keyframes move-up {
	from {
		transform: translateY(0px);
	}

	to {
		transform: translateY(-40px);
	}
}

.game-tile__token_images {
	position: absolute;
	right: 12px;
	top: 12px;
	display: flex;
	gap: 8px;
}